<template>
  <Disclosure v-if="authUser" as="nav" class="position" v-slot="{ open }">
    <div
      class="sm:max-w-7xl mx-auto px-2 sm:px-4 md:px-6 lg:px-8 shadow-lg bg-gray-800 rounded-b-md"
    >
      <div class="relative flex h-16 items-center justify-between">
        <div
          class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
        >
          <RouterLink to="/">
            <div class="flex flex-shrink-0 items-center">
              <img
                class="w-16 h-auto"
                src="@/assets/logo2.png"
                alt="Interactive Map"
              />
            </div>
          </RouterLink>
          <!-- <div class="hidden sm:ml-6 sm:block text-red-400 pt-2">
            <span>Welcome {{ authUser.name }} </span> <br />
            <span>Role: {{ authUser.roles[0] }}</span>
          </div> -->
        </div>
        <!-- <div class="flex flex-shrink-0 items-center">
          <img
            class="w-20 h-auto"
            src="@/assets/logoSdg.png"
            alt="Interactive Map"
          />
        </div> -->
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <button
            v-if="hasAdminOrAuthorityRole(authUser)"
            type="button"
            class="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <span class="absolute -inset-1.5"></span>
            <RouterLink to="/admin">
              <v-icon style="font-size: 2.2rem" aria-hidden="true"
                >mdi mdi-developer-board</v-icon
              >
            </RouterLink>
          </button>

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <MenuButton
              class="relative flex rounded-full bg-gray-800 text-gray-400 hover:text-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open user menu</span>

              <v-icon style="font-size: 2.2rem" aria-hidden="true"
                >mdi mdi-account-circle</v-icon
              >
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem>
                  <div
                    class="block px-4 py-2 text-sm text-gray-700 font-semibold"
                  >
                    {{ authUser.name }}
                  </div>
                </MenuItem>

                <div v-if="authUser && authUser.roles[0] == 'user'">
                  <MenuItem v-slot="{ active }">
                    <RouterLink to="/user-sesizari">
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 ',
                        ]"
                        >Sesizarile Tale</a
                      >
                    </RouterLink>
                  </MenuItem>
                </div>
                <MenuItem v-slot="{ active }">
                  <RouterLink to="/profile">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Your Profile</a
                    >
                  </RouterLink>
                </MenuItem>
                <div v-if="hasAdminRole(authUser)">
                  <MenuItem v-slot="{ active }">
                    <RouterLink to="/users">
                      <!-- asta apare doar daca esti admin -->
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >Manage Users</a
                      >
                    </RouterLink>
                  </MenuItem>
                </div>
                <MenuItem v-slot="{ active }">
                  <a
                    @click="logout"
                    href="#"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                    >Sign out</a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <!-- <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
          item.current
          ? 'bg-gray-900 text-white'
          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
          'block rounded-md px-3 py-2 text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
          > -->
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { RouterLink } from "vue-router";
import { logout } from "../composables/GetData";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
const store = useAuthStore();
const { authUser } = storeToRefs(store);

function hasAdminOrAuthorityRole(user) {
  return (
    (user && user.roles.includes("admin")) ||
    (user && user.roles.includes("autoritate"))
  );
}
function hasAdminRole(user) {
  return user && user.roles && user.roles.includes("admin");
}
</script>
<style>
.position {
  position: fixed;
  top: 0px;
  width: 100vw;
}
.nav-bg {
  background-color: rgb(243, 244, 246);
}
</style>
