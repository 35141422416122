<template>
  <!--  -->
  <div class="flex items-center justify-center min-h-screen p-2 bg-gray-100">
    <div class="w-full max-w-md p-6 space-y-8 bg-white rounded-lg shadow-lg">
      <h2 class="text-2xl font-bold text-center text-gray-900">
        Sign in to your account
      </h2>
      <form
        @submit.prevent="handleSubmit"
        class="space-y-6"
        action="#"
        method="POST"
      >
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-left text-gray-900"
            >Email address</label
          >
          <div class="mt-2">
            <input
              v-model="loginData.email"
              id="email"
              name="email"
              type="text"
              autocomplete="email"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1 px-1"
            />
            <span
              v-if="v$.email.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ v$.email.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Password</label
            >
            <!-- <div class="text-sm">
              <RouterLink
                to="forgot-password"
                class="font-semibold text-indigo-600 hover:text-indigo-500"
                >Forgot password?</RouterLink
              >
            </div> -->
          </div>
          <div class="mt-2">
            <input
              v-model="loginData.password"
              id="password"
              name="password"
              type="password"
              @input="showPasswordError = false"
              autocomplete="current-password"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1 px-1"
            />
            <span
              v-if="showPasswordError && !v$.password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              Datele pe care le-ați introdus sunt incorecte. Vă rugăm să
              încercați din nou.
            </span>
            <span
              v-if="v$.password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ v$.password.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div>
          <button
            @click="login"
            type="submit"
            class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign in
          </button>
        </div>
      </form>

      <p class="text-sm text-center text-gray-500">
        Not a member?
        <RouterLink
          to="/register"
          class="font-semibold text-indigo-600 hover:text-indigo-500"
          >Create account</RouterLink
        >
      </p>
    </div>
  </div>
</template>
<script setup>
import { RouterLink, useRouter } from "vue-router";
import { ref, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import axios from "../AxiosConfig";
import { storeToRefs } from "pinia";
const router = useRouter();
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  minLength,
  sameAs,
} from "@vuelidate/validators";
const showPasswordError = ref(false);

const storeAuth = useAuthStore();
const { isAuthenticated, userAnonim } = storeToRefs(storeAuth);

const loginData = ref({
  email: "",
  password: "",
  // remember: true,
});

const rulesLogin = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Introdu o adresă de email", required),
      email: helpers.withMessage("Introduceți o adresă de email validă", email),
    },
    password: {
      required: helpers.withMessage("Introdu o parolă", required),
      minLength: helpers.withMessage(
        "Parola trebuie să aibă cel puțin 8 caractere",
        minLength(8)
      ),
    },
  };
});
const v$ = useVuelidate(rulesLogin, loginData.value);
const handleSubmit = async () => {
  const validateLogin = await v$.value.$validate();

  if (validateLogin) {
    axios
      .post("/login", loginData.value)
      .then((response) => {
        storeAuth.setData(response.data);
        isAuthenticated.value = true;
        window.localStorage.setItem("isAuthenticated", true);
        userAnonim.value = false;
        window.localStorage.setItem("userAnonim", false);
        router.push(router.currentRoute.value.query.redirect || "/"); //redirect to intended route
        // Resetarea stării validării
        showPasswordError.value = false;
        loginData.value.email = "";
        loginData.value.password = "";
        v$.value.$reset();
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          showPasswordError.value = true;
        }
      });
  }
};
</script>
<style></style>
