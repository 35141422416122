<template>
  <button
    @click="showPopUp = true"
    class="mainBtn bg-blue-500 text-white p-2 rounded-full top-6"
  >
    <v-icon style="font-size: 2.5rem" icon="mdi-account-multiple" />
  </button>
  <div
    v-show="showPopUp"
    class="overlay fixed top-0 left-0 w-full h-full flex items-center justify-center"
  >
    <div
      ref="popupTargetOutside"
      @click="closePopup"
      class="welcomePopUp bg-white p-4 rounded shadow-md max-w-md"
    >
      <!-- Conținutul Popup-ului -->
      <h5 class="text-center pb-3 text-lg font-bold">Atenție!</h5>
      <p class="leading-6 text-center">
        Bine ați venit pe pagina de sesizări online, sesizările efectuate se pot
        salva în format anonimat
        <span class="underline">(fără vizualizare istoric)</span> sau cu creare
        de cont.
      </p>
      <div class="welcome-buttons flex justify-center mt-4">
        <button
          @click="
            userAnonim = true;
            showPopUp = false;
          "
          class="bg-blue-500 text-white px-4 py-2 mr-2 rounded"
        >
          Anonim
        </button>
        <RouterLink to="/register">
          <button class="bg-green-500 text-white px-4 py-2 mr-2 rounded">
            Înregistrare
          </button>
        </RouterLink>
        <RouterLink to="/login">
          <button class="bg-yellow-500 text-white px-4 py-2 rounded">
            Autentificare
          </button>
        </RouterLink>
      </div>
      <h5 class="text-center mt-4 font-bold">Vă mulțumim !</h5>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { RouterLink } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

const showPopUp = ref(true);
const popupTargetOutside = ref(null);
const store = useAuthStore();
const { userAnonim } = storeToRefs(store);

const storageUser = window.localStorage.getItem("userAnonim");

if (storageUser) {
  userAnonim.value = JSON.parse(storageUser); //din string in boolean
  // onClickOutside(popupTargetOutside, () => (showPopUp.value = false));
  showPopUp.value = false;
  //daca pe parcurs userAnonim se face false=> showpopup
  if (!JSON.parse(storageUser)) showPopUp.value = true;
}

watch(userAnonim, (val) => {
  window.localStorage.setItem("userAnonim", val);
});
</script>

<style scoped>
.mainBtn {
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.welcomePopUp {
  border: 1px solid black;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
