export const geojsonTesluiOltCoords = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [
            [24.3310160633325, 44.50849080080951],
            [24.340177729118125, 44.50790890563907],
            [24.348860139559605, 44.50823128329725],
            [24.356841197153386, 44.5104902940449],
            [24.362610112730508, 44.51164557266053],
            [24.36687302292674, 44.5133653613627],
            [24.375362397672973, 44.522168439828135],
            [24.390010586770842, 44.5336811030749],
            [24.404653924211857, 44.545732206900226],
            [24.40626765235912, 44.54751224150745],
            [24.406812748889884, 44.548247708063094],
            [24.40629492889977, 44.54918024523556],
            [24.404576729545884, 44.5498659262308],
            [24.40053078381849, 44.54945692766431],
            [24.396657042374628, 44.54892738282584],
            [24.39639263426864, 44.54902769572689],
            [24.396372664135406, 44.549043477360485],
            [24.393895372808572, 44.550606656120465],
            [24.389774613815298, 44.552883085324886],
            [24.38563086319887, 44.554964355412224],
            [24.375086936818178, 44.5588612540667],
            [24.372245094024585, 44.56181137153113],
            [24.367478337559323, 44.5652150942728],
            [24.362282163166846, 44.565096107567314],
            [24.36193029679556, 44.56923359432242],
            [24.35638362957544, 44.57010298096621],
            [24.35509586807392, 44.57150501006735],
            [24.353346987760837, 44.571110665566394],
            [24.351325948009258, 44.57065860182493],
            [24.348347064348324, 44.56815521730396],
            [24.346407027929686, 44.56811485550229],
            [24.34190518577369, 44.56717332501567],
            [24.34066060122825, 44.569666427878495],
            [24.341538138325962, 44.573451704515975],
            [24.342070359173412, 44.57482382728481],
            [24.339685602145607, 44.57674001044984],
            [24.338447395878234, 44.576391483819435],
            [24.337301993362928, 44.57555894036153],
            [24.333345094209307, 44.57315873617995],
            [24.332826349660998, 44.57213577774779],
            [24.332766381191945, 44.570660644144056],
            [24.33315429785611, 44.5684424515199],
            [24.335419582909694, 44.56758332781814],
            [24.338057585309457, 44.56704173322339],
            [24.338820654764405, 44.56417206114219],
            [24.338143029117084, 44.562728796245295],
            [24.338257383657492, 44.56126870281685],
            [24.34027752578848, 44.558528683679015],
            [24.338087500387047, 44.551481871620695],
            [24.33870875529351, 44.54702578657549],
            [24.331620062428925, 44.53614415726432],
            [24.329930247040807, 44.533071802410575],
            [24.329425844249727, 44.52542907180812],
            [24.329511499825884, 44.51700932198105],
            [24.331762428264682, 44.51224353558561],
            [24.331283494933842, 44.509708606252474],
            [24.3310160633325, 44.50849080080951],
          ],
        ],
        type: "Polygon",
      },
    },
  ],
};
