<template>
  <TransitionRoot as="template" :show="openSlideOverPanel">
    <Dialog as="div" class="relative z-10" @close="setSliderClose">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        ></div>
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none"
          >
            <!-- slider -->
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 "
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 "
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="relative w-screen max-w-md pointer-events-auto"
              >
                <!-- X-ul -->
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div
                    class="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4"
                  >
                    <button
                      v-on:click="setSliderClose"
                      type="button"
                      class="relative text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span class="absolute -inset-2.5"></span>
                      <span class="sr-only">Close panel</span>
                      <v-icon icon="mdi-close" />
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex flex-col h-full py-6 bg-white shadow-xl">
                  <div class="px-4 sm:px-6">
                    <DialogTitle
                      class="text-base font-semibold leading-6 text-gray-900"
                      >Adaugă Sesizare</DialogTitle
                    >
                  </div>
                  <div class="relative flex-1 px-4 mt-6 sm:px-6">
                    <!-- Your content FORM-->
                    <form @submit.prevent="submitForm" class="max-w-md mx-auto">
                      <div class="mb-1">
                        <label
                          for="categoria"
                          class="block text-sm font-medium text-gray-700"
                          >Selectează categoria</label
                        >
                        <select
                          v-model="newComplaint.categoria"
                          id="categoria"
                          name="categoria"
                          class="w-full p-2 mt-1 border rounded"
                        >
                          <option value="0" disabled selected>
                            Selectează o categorie
                          </option>
                          <option
                            v-for="categorie in categorii"
                            :key="categorie.id"
                            :value="categorie.id"
                          >
                            {{ categorie.nume }}
                          </option>
                        </select>
                        <!-- <span
                          v-if="v$.categoria.$error"
                          class="py-1 my-1 text-sm font-semibold text-red-500 rounded"
                        ></span> -->
                        <span
                          class="py-1 my-1 text-sm font-semibold text-red-500 rounded"
                          v-for="error in v$.categoria.$errors"
                          :key="error.$uid"
                          >{{ error.$message }}</span
                        >
                      </div>
                      <!-- DESCRIERE -->
                      <div class="relative mb-1">
                        <label
                          for="descriere"
                          class="block text-sm font-medium text-gray-700"
                          >Descrierea sesizării</label
                        >
                        <textarea
                          v-model="newComplaint.descriere"
                          :maxlength="descriere_maxlength"
                          id="descriere"
                          name="descriere"
                          rows="4"
                          class="w-full p-2 mt-1 border rounded"
                        ></textarea>
                        <span
                          class="absolute top-0 right-0 mt-1 mr-1 text-sm"
                          :class="{
                            'text-gray-500':
                              newComplaint.descriere.length <
                              descriere_maxlength,
                            'text-red-500':
                              newComplaint.descriere.length ===
                              descriere_maxlength,
                          }"
                        >
                          {{
                            descriere_maxlength - newComplaint.descriere.length
                          }}/{{ descriere_maxlength }}
                        </span>
                        <span
                          v-if="v$.descriere.$error"
                          class="py-1 my-1 text-sm font-semibold text-red-500 rounded"
                          >{{ v$.descriere.$errors[0].$message }}</span
                        >
                      </div>

                      <!--ADD IMAGINE-->
                      <div class="mb-3">
                        <p class="block text-sm font-medium text-gray-700">
                          Adaugă imagini
                        </p>
                        <input
                          type="file"
                          accept="image/*"
                          name="imagini"
                          multiple
                          @input="unprocessableContentError = false"
                          @change="handleFileChange($event)"
                          class="w-full p-2 mt-1 border rounded"
                        />
                        <span
                          v-if="unprocessableContentError"
                          class="py-1 my-1 text-sm font-semibold text-red-500 rounded"
                        >
                          Fișierele introduse trebuie să fie de tip: jpg, bmp,
                          png.
                        </span>
                      </div>
                      <!-- TERMENI&CONDITII -->
                      <!-- <div class="mb-3">
                        <input
                          v-model="newComplaint.termeni_conditii"
                          type="checkbox"
                          name="termeni-conditii"
                        />
                        Sunt de acord cu
                        <a class="font-medium underline" href="#"
                          >termenii și condițiile</a
                        >
                        <div
                          v-if="v$.termeni_conditii.$error"
                          class="py-1 my-1 text-sm font-semibold text-red-500 rounded"
                        >
                          Trebuie să accepți termenii și condițiile
                        </div>
                      </div> -->
                      <vue-recaptcha
                        :v-show="true"
                        :sitekey="recaptcha_key"
                        size="normal"
                        theme="light"
                        :loading-timeout="loadingTimeout"
                        @verify="recaptchaVerified"
                        @expire="recaptchaExpired"
                        @fail="recaptchaFailed"
                        @error="recaptchaError"
                        ref="myRecaptcha"
                      >
                      </vue-recaptcha>
                      <span
                        v-if="v$.recaptchaToken.$error"
                        class="py-1 my-1 text-sm font-semibold text-red-500 rounded"
                        >{{ v$.recaptchaToken.$errors[0].$message }}</span
                      >
                      <div class="flex justify-start mt-5">
                        <!-- <button
                                            @click="setSliderClose()"
                                            type="button"
                                            class="p-2 text-white bg-red-500 border rounded"
                                          >
                                            Renunță
                                          </button> -->
                        <button
                          @click="submit"
                          type="submit"
                          class="p-2 text-white bg-green-500 border rounded"
                        >
                          Trimite Sesizare
                        </button>
                      </div>
                      <!-- <span v-for="error in v$.$errors" :key="error.$uid"
                        >{{ error.$property }}-{{ error.$message }}</span
                      > -->
                    </form>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ref, watch, computed, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import vueRecaptcha from "vue3-recaptcha2";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";

let loadingTimeout = 30000; // 30 seconds
let descriere_maxlength = 255;
let recaptcha_key = process.env.VUE_APP_CAPTCHA_KEY;
const myRecaptcha = ref(null);
const unprocessableContentError = ref(false);

const storeAuth = useAuthStore();
const { access_token, isAuthenticated } = storeToRefs(storeAuth);

const { openSlideOverPanel, markerCoordinates } = defineProps([
  "openSlideOverPanel",
  "markerCoordinates",
]);
const emits = defineEmits(["set-slide-open", "update-sesizare"]);
const categorii = ref();

//preluare coordonate din home
let coordonateMarker = ref({ lat: 0, lng: 0 });
watch(markerCoordinates, (homeViewCoordonate) => {
  coordonateMarker = homeViewCoordonate;
});
// const complaint = ref([]);
const newComplaint = ref({
  descriere: "",
  categoria: 0,
  raspuns: null,
  status: 0,
  termeni_conditii: false,
  recaptchaToken: null,
  images: [],
});
//Recaptcha
function recaptchaVerified(response) {
  newComplaint.value.recaptchaToken = response;
}
function recaptchaExpired() {
  newComplaint.value.recaptchaToken = "";
  myRecaptcha.value.reset();
}
function recaptchaFailed() {
  newComplaint.value.recaptchaToken = "";
  v$.$errors.recaptchaToken = [
    "Verificarea reCAPTCHA a eșuat. Vă rugăm să încercați din nou să dați refresh la pagină.",
  ];
}
function recaptchaError(reason) {
  console.error("Eroare la verificarea reCAPTCHA:", reason);
  v$.$errors.recaptchaToken = [
    "A apărut o eroare la verificarea reCAPTCHA. Vă rugăm să încercați din nou sau să să dați refresh la pagină.",
  ];
}

const setSliderClose = () => {
  newComplaint.value.categoria = 0;
  newComplaint.value.termeni_conditii = false;
  newComplaint.value.descriere = "";
  newComplaint.value.images = [];
  v$.value.$reset(); // Resetarea stării validării
  emits("set-slide-open", false);
};
const categoryExists = (value) => {
  const categoryId = parseInt(value);
  if (isNaN(categoryId) || categoryId === 0) {
    return false;
  }
  // Verifică dacă ID-ul categoriei există în lista de categorii
  return categorii.value.some((categorie) => categorie.id === categoryId);
};
const termeni_required = (value) => {
  return value === true;
};
const rules = computed(() => {
  return {
    categoria: {
      required,
      categoryExists: helpers.withMessage(
        "Vă rugăm să selectați o categorie din listă.",
        categoryExists
      ),
    },
    descriere: {
      required: helpers.withMessage(
        "Trebuie să adaugați o descriere sesizări.",
        required
      ),
      minLength: helpers.withMessage(
        "Vă rugăm să spune-ți mai mult despre această sesizare.",
        minLength(15)
      ),
    },
    recaptchaToken: {
      required: helpers.withMessage(
        'Vă rugăm să bifați căsuța "Nu sunt robot".',
        required
      ),
    },
    // termeni_conditii: { required, termeni_required },
  };
});
const v$ = useVuelidate(rules, newComplaint.value);

function handleFileChange(event) {
  newComplaint.value.images = event.target.files;
}
const submit = async () => {
  const resultValidate = await v$.value.$validate();
  if (resultValidate) {
    let formData = new FormData();
    formData.append("categoria", newComplaint.value.categoria);
    formData.append("coordonate[lat]", coordonateMarker.lat);
    formData.append("coordonate[lng]", coordonateMarker.lng);
    formData.append("subsemnat_descriere", newComplaint.value.descriere);
    const termeni_conditii_value = newComplaint.value.termeni_conditii ? 1 : 0;
    formData.append("termeni_conditii", 1);
    for (let i = 0; i < newComplaint.value.images.length; i++) {
      formData.append("photos[]", newComplaint.value.images[i]); // adaugare imagini in formData
    }
    formData.append("g-recaptcha-response", newComplaint.value.recaptchaToken);

    GetData(async () => {
      const headers = isAuthenticated.value
        ? { Authorization: `Bearer ${access_token.value}` }
        : {};
      const response = await axios.post("/", formData, { headers });
      unprocessableContentError.value = false;
      emits("update-sesizare", response.data.data);
      setSliderClose();
    }).catch((error) => {
      console.log(error);
      if (error.response.status === 422) {
        unprocessableContentError.value = true;
        newComplaint.value.images = [];
      }
      recaptchaExpired();
    });
  }
};

onMounted(() => {
  GetData(async () => {
    const response = await axios.get("/categorii");
    categorii.value = response.data.data;
  });
});
</script>
<style></style>
