<template>
  <div class="background-container pt-16">
    <Navbar />

    <button
      @click="router.go(-1)"
      class="font-black px-4 py-2 mt-2 bg-black rounded-tr-md rounded-br-md text-white hover:bg-gray-800 transition duration-100 ease-in-out"
    >
      Înapoi
    </button>
    <div class="container mx-auto overflow-x-auto">
      <h2 class="my-4 text-3xl font-extrabold mb-4 text-center">
        {{ sesizariPerCategorie.nume }}
      </h2>
      <div v-if="isLoading" class="flex justify-center items-center font-bold">
        <p class="text-lg">Se încarcă sesizăriile...</p>
      </div>
      <div
        v-else-if="sesizariPerCategorie.sesizari.length === 0"
        class="flex justify-center items-center font-bold"
      >
        <p class="text-lg">
          Nu aveți încă sesizări asociate acestei categorii.
        </p>
      </div>
      <table
        v-else-if="sesizariPerCategorie.sesizari.length > 0"
        class="w-full border-collapse border border-gray-400 bg-gray-100"
      >
        <thead>
          <tr class="bg-gray-200">
            <th class="border p-2">Descriere</th>
            <th class="border p-2">Stare</th>
            <th class="border p-2 hidden sm:table-cell">Raspuns</th>
            <th class="border p-2 hidden sm:table-cell">Adăugat la</th>
            <th class="border p-2">Locația</th>
            <th colspan="2" class="border p-2">Acțiuni</th>
          </tr>
        </thead>
        <tbody class="text-center font-semibold">
          <tr
            v-for="sesizare in sesizariPerCategorie.sesizari"
            :key="sesizare.id"
          >
            <td class="border p-2 text-balance">
              <span v-if="sesizare.descriere.length > 40">
                {{ sesizare.descriere.substring(0, 40) + "..." }}</span
              >
              <span v-else>{{ sesizare.descriere }}</span>
            </td>
            <td class="border p-2">
              {{ getStatusText(sesizare.status) }}
            </td>
            <td class="border p-2 hidden sm:table-cell text-balance">
              <span
                v-if="sesizare.raspuns !== null && sesizare.raspuns.length < 40"
                >{{ sesizare.raspuns }}</span
              >
              <span
                v-else-if="
                  sesizare.raspuns !== null && sesizare.raspuns.length > 30
                "
              >
                {{ sesizare.raspuns.substring(0, 30) + "..." }}
              </span>
              <span v-else>Niciun răspuns încă</span>
            </td>
            <td class="border p-2 hidden sm:table-cell">
              {{ sesizare.created_at }}
            </td>
            <td class="border p-2">
              <button
                @click="redirectToMapSesizare(sesizare.id)"
                class="text-green-500 hover:text-green-700 hover:underline text-balance"
              >
                Vezi pe hartă
              </button>
            </td>
            <td class="border p-2">
              <button
                @click="editareSesizare(sesizare)"
                class="text-blue-500 hover:text-blue-700 hover:underline text-balance mr-3"
              >
                Editare
              </button>
            </td>
            <td class="border p-2">
              <button
                @click="redirectToDetails(sesizare.id)"
                class="text-blue-500 hover:text-blue-700 hover:underline text-balance"
              >
                Vezi mai mult
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <EditComplaint
      v-if="complaint"
      :is-edit-complaint-opened="isEditComplaintOpened"
      @is-edit-complaint-opened="
        (newValue) => {
          isEditComplaintOpened = newValue;
          complaint = null;
        }
      "
      :complaint-to-edit="complaint"
      @edited-completed="updateComplaint"
    />
  </div>
</template>
<script setup>
import Navbar from "../components/Navbar.vue";
import { ref, onMounted } from "vue";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import router from "@/router";
import EditComplaint from "@/components/EditComplaint.vue";

const storeAuth = useAuthStore();
const { access_token } = storeToRefs(storeAuth);
const isEditComplaintOpened = ref(false);
const route = useRoute();
const categorieId = ref(route.params.id);

const isLoading = ref(true);
const complaint = ref();
const sesizariPerCategorie = ref({
  nume: "",
  sesizari: [],
});

function editareSesizare(sesizare) {
  isEditComplaintOpened.value = true;
  complaint.value = sesizare;
}

const updateComplaint = (newState) => {
  // const index = sesizariPerCategorie.value.sesizari.findIndex(
  //   (sesizare) => sesizare.id === newState.id
  // );
  // if (index !== -1) {
  //   // Actualizează utilizatorul cu noile date
  //   sesizariPerCategorie.value.sesizari[index] = { ...newState };
  // }
  GetData(async () => {
    const response = await axios.get(`/categorii/${categorieId.value}`, {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });
    sesizariPerCategorie.value = response.data.data;
  }).catch((error) => {
    if (error.response && error.response.status === 404) {
      router.push({ name: "not-found" });
    }
  });
};

const redirectToMapSesizare = (sesizareId) => {
  router.push({ name: "sesizari-map", params: { id: sesizareId } });
};
const redirectToDetails = (sesizareId) => {
  router.push({ name: "about-complaint", params: { id: sesizareId } });
};

function getStatusText(status) {
  switch (status) {
    case 0:
    case "0":
      return "Preluat";
    case 1:
    case "1":
      return "În lucru";
    case 2:
    case "2":
      return "Finalizat";
    case 3:
    case "3":
      return "Anulat";
    default:
      return "Status necunoscut";
  }
}

onMounted(() => {
  GetData(async () => {
    const response = await axios.get(`/categorii/${categorieId.value}`, {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });
    sesizariPerCategorie.value = response.data.data;

    isLoading.value = false;
  }).catch((error) => {
    if (error.response && error.response.status === 404) {
      router.push({ name: "not-found" });
    }
  });
});
</script>
<style scoped>
@media (max-width: 645px) {
}
</style>
