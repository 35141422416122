<template>
  <div
    v-if="isPopupOpened"
    class="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50"
  >
    <div class="bg-white p-8 rounded-lg shadow-md w-96">
      <span
        class="absolute top-0 right-0 p-2 cursor-pointer"
        @click="closeModal"
        >&times;</span
      >
      <h2 class="text-lg font-bold mb-4">Avertizare</h2>
      <div class="mb-6">
        <p v-if="itemType === 'user'">
          Sunteți sigur că doriți să ștergeți utilizatorul:
          <span class="underline font-medium">{{ itemToDelete.name }}</span> ?
        </p>
        <p v-else-if="itemType === 'categorie'">
          Sunteți sigur că doriți să ștergeți categoria
          <span class="underline font-medium">{{ itemToDelete.nume }}</span>
          și toate sesizările din ea?
        </p>
      </div>
      <div class="flex justify-end space-x-4">
        <button
          @click="emits('set-popup-close', false)"
          class="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Renunță
        </button>
        <button
          @click="stergeCategorie"
          class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Șterge
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from "vue";

const props = defineProps(["item-to-delete", "item-type", "is-popup-opened"]);
const { itemToDelete, itemType, isPopupOpened } = toRefs(props);
const emits = defineEmits(["set-popup-close", "delete-item"]);

const stergeCategorie = () => {
  emits("delete-item", itemToDelete.value.id);
  emits("set-popup-close", false);
};
</script>
