import axios from "../AxiosConfig";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

export default async function getData(getDynamicEndpoint) {
  try {
    await getDynamicEndpoint();
  } catch (error) {
    if (error.response.status == 403) {
      await refreshAccessToken();
      router.push("/forbidden");
    }
    if (error.response.status == 401) {
      await refreshAccessToken();
      await getDynamicEndpoint();
    }
    if (error.response.status !== 401 && error.response.status !== 403)
      throw error;
  }
}
const refreshAccessToken = async () => {
  const storeAuth = useAuthStore();
  const { refresh_token } = storeToRefs(storeAuth);
  try {
    const response = await axios.post(
      "/oauth/refresh",
      {},
      {
        headers: {
          Authorization: `Bearer ${refresh_token.value}`,
        },
      }
    );
    storeAuth.setData(response.data);
    console.log("Token de acces actualizat!");
  } catch (refreshError) {
    if (refreshError.message === "Network Error") {
      console.log("Nu se poate realiza conexiunea cu serverul");
    }
    console.log(refreshError);
    forceLogout();
    router.push("/");
  }
};
export const logout = async () => {
  try {
    const storeAuth = useAuthStore();
    const { refresh_token } = storeToRefs(storeAuth);
    await axios.post(
      "/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${refresh_token.value}`,
        },
      }
    );
    forceLogout();
    router.push("/");
  } catch (error) {
    await refreshAccessToken();
    await logout();
  }
};

export const forceLogout = () => {
  const storeAuth = useAuthStore();
  const {
    isAuthenticated,
    userAnonim,
    authUser,
    allUsers,
    refresh_token,
    access_token,
  } = storeToRefs(storeAuth);

  authUser.value = null;
  allUsers.value = [];
  isAuthenticated.value = null;
  userAnonim.value = null;
  access_token.value = null;
  refresh_token.value = null;
  window.localStorage.removeItem("isAuthenticated");
  window.localStorage.removeItem("userAnonim");
  window.localStorage.removeItem("authUser");
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
};
