<template>
  <div class="w-full overflow-hidden">
    <l-map
      style="z-index: 0; height: 100vh"
      ref="map"
      v-model:zoom="zoom"
      :center="[44.54214939438825, 24.360127073695935]"
      :minZoom="minZoom"
      :options="{ zoomControl: false }"
      @click="addMarker"
    >
      <!--enter: [26.23861312866211, 47.65024065090939], // Centrul Suceava
      center: [46.1841, 25.2224], // Centrul pe România
      center: [47.65024065090939, 26.23861312866211], // Centrul Suceava -->
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-geo-json :geojson="geojsonTesluiOltCoords"></l-geo-json>
      <div
        v-if="complaintsData"
        v-for="complaint in complaintsData"
        :key="complaint.id"
      >
        <l-marker
          :lat-lng="[complaint.coordonate.lat, complaint.coordonate.lng]"
        >
          <l-popup
            :lat-lng="[complaint.coordonate.lat, complaint.coordonate.lng]"
          >
            <PopupContent
              :complaint="complaint"
              @view-details="redirectToDetails"
            />
          </l-popup>
        </l-marker>
      </div>
      <l-control-zoom position="bottomleft"></l-control-zoom>
      <!-- :icon="getIcon(complaint)" -->
      <!-- <l-control-zoom position="bottomright"></l-control-zoom> -->
    </l-map>
  </div>
  <AddComplaint
    :openSlideOverPanel="isSlideOverPanelOpen"
    :markerCoordinates="() => markerCoordinates"
    @set-slide-open="updateSlideOverPanel"
    @update-sesizare="updateComplaintsData"
  />
  <!-- @set-marker-data="updateComplaintsData" -->
  <Navbar v-if="isAuthenticated" />
  <WelcomePopup v-if="!isAuthenticated" />
</template>

<script setup>
import "../assets/tailwind.css";
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LGeoJson,
  LMarker,
  LPopup,
  LControlZoom,
} from "@vue-leaflet/vue-leaflet";
import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import WelcomePopup from "@/components/WelcomePopup.vue";
import AddComplaint from "../components/AddComplaint.vue";
import PopupContent from "../components/PopupContent.vue";
import Navbar from "@/components/Navbar.vue";
import * as turf from "@turf/turf";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { geojsonTesluiOltCoords } from "@/composables/JudetePolygons";
// ---------Variabile---------
const storeAuth = useAuthStore();
const { isAuthenticated, access_token, authUser } = storeToRefs(storeAuth);
const zoom = ref(13);
const minZoom = ref(6); //scrollOut
const complaintsData = ref([]);
const categorii = ref([]);
const markerCoordinates = ref({ lat: 0, lng: 0 });
const isSlideOverPanelOpen = ref(false);
const showPopupBtn = ref(false);

const updateSlideOverPanel = (newState) => {
  isSlideOverPanelOpen.value = newState;
};
const updateComplaintsData = (newState) => {
  complaintsData.value.push(newState);
};
const redirectToDetails = (complaint) => {
  router.push({ name: "about-complaint", params: { id: complaint.value.id } });
};

const addMarker = (event) => {
  const map = event.target;
  const latlng = event.latlng;
  // Verifică dacă punctul este în interiorul poligonului
  const polygonCoords =
    geojsonTesluiOltCoords.features[0].geometry.coordinates[0]; //coordonate polygon
  const isInsidePolygon = turf.booleanPointInPolygon(
    [latlng.lng, latlng.lat],
    turf.polygon([polygonCoords])
  );
  if (isInsidePolygon) {
    isSlideOverPanelOpen.value = true;
    markerCoordinates.value = { lat: latlng.lat, lng: latlng.lng };
    // Adaug markerul in harta
    const marker = new L.Marker(latlng);
    map.addLayer(marker);

    watch(isSlideOverPanelOpen, (newState) => {
      if (!newState) {
        map.removeLayer(marker);
      }
    });
  }
};

function getIcon(complaint) {
  //daca e autoritate
  if (
    authUser.value.roles[0] == "autoritate" &&
    categorii.value.includes(complaint.categorie)
  ) {
    showPopupBtn.value = true;
    return L.icon({
      iconUrl: require(`@/assets/green_marker.png`),
      iconSize: [37, 39],
      iconAnchor: [18, 39],
      popupAnchor: [0, -35],
    });
    //daca e user
  } else if (
    authUser.value.roles[0] == "user" &&
    authUser.value.id === complaint.user.user_id
  ) {
    showPopupBtn.value = true;
    return L.icon({
      iconUrl: require(`@/assets/green_marker.png`),
      iconSize: [37, 39],
      iconAnchor: [18, 39],
      popupAnchor: [0, -35],
    });
  } else {
    // Returnează markerul implicit
    showPopupBtn.value = false;
    return L.icon({
      iconUrl: require(`@/assets/default_marker.png`),
      iconSize: [37, 39],
      iconAnchor: [18, 39],
      popupAnchor: [0, -35],
    });
  }
}
const getComplaintsByRoles = (url) => {
  GetData(async () => {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });
    complaintsData.value = response.data.data;
  }).catch((error) => {
    console.log(error);
  });
};
getComplaintsByRoles("/");
onMounted(() => {
  if (authUser.value && authUser.value.roles[0] === "user") {
    getComplaintsByRoles("/user-sesizare");
  }

  if (authUser.value && authUser.value.roles[0] === "autoritate") {
    getComplaintsByRoles("/categorii/authority");
  }
});
watch(authUser, (newAuthUser) => {
  // Verifică dacă authUser devine null adica daca s-a dat logout
  if (newAuthUser === null) {
    getComplaintsByRoles("/");
  }
});
</script>

<style></style>
