<template>
  <Navbar v-if="isAuthenticated" />
  <div
    v-if="complaint"
    class="background-container pt-14 flex justify-center bg-gray-100"
  >
    <div class="bg-white p-8 shadow-md rounded-md w-full max-w-xl m-1">
      <h2 class="text-3xl font-semibold text-center">Detalii Sesizare</h2>
      <div
        v-if="isLoading"
        class="flex justify-center items-center font-bold mt-10"
      >
        <p class="text-lg">Se încarcă detaliile...</p>
      </div>
      <div v-else class="mt-4">
        <div class="flex items-center justify-between">
          <div class="pb-2">
            <span class="font-bold">Categorie: </span>
            <span class="font-semibold">{{ complaint.categorie_name }}</span>
          </div>
          <button
            v-if="authUser && authUser.roles[0] !== 'user'"
            @click="isEditComplaintOpened = true"
            class="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
          >
            Editare
          </button>
        </div>
        <div class="pb-2">
          <span class="font-bold">Status: </span>
          <span class="font-semibold">{{
            getStatusText(complaint.status)
          }}</span>
        </div>
        <!-- Userul care a creat sesizare-->
        <div v-if="complaint.user" class="pb-2">
          <div class="grid grid-cols-2 gap-4">
            <div>
              <span class="font-bold">Creat de: </span>
              <span>{{ complaint.user.name }}</span>
            </div>
            <div>
              <span class="font-bold">Email: </span>
              <span>{{ complaint.user.email }}</span>
            </div>
          </div>
        </div>
        <div class="pb-2">
          <span class="font-bold">Descriere:</span>
          <div class="border rounded min-h-24 pl-1">
            <p class="m-0">
              {{ complaint.descriere }}
            </p>
          </div>
        </div>

        <div v-if="complaint.raspuns">
          <span class="font-bold">Raspuns:</span>
          <div class="border rounded min-h-24 pl-1">
            <p class="m-0">
              {{ complaint.raspuns }}
            </p>
          </div>
        </div>
        <!-- Imagini -->
        <div
          v-if="complaint.images && complaint.images.length > 0"
          class="mt-6"
        >
          <h3 class="text-xl font-semibold mb-2">Imagini</h3>
          <!-- <div class="grid grid-cols-2 gap-4">
            <img
              :src="complaint.images[0].url"
              alt="Imagine sesizare"
              class="w-full h-auto"
            />
          </div> -->
          <div class="grid grid-cols-2 gap-4">
            <img
              v-for="(image, index) in complaint.images"
              :key="index"
              :src="image.url"
              alt="Imagine sesizare"
              class="w-full h-auto"
            />
          </div>
        </div>
        <div class="flex justify-end">
          <span class="font-bold">Data: </span>
          <span>{{ complaint.created_at }}</span>
        </div>
      </div>
    </div>
    <EditComplaint
      v-if="complaint"
      :is-edit-complaint-opened="isEditComplaintOpened"
      @is-edit-complaint-opened="
        (newValue) => {
          isEditComplaintOpened = newValue;
        }
      "
      :complaint-to-edit="complaint"
      @edited-completed="updateComplaint"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import Navbar from "@/components/Navbar.vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import GetData from "@/composables/GetData";
import axios from "../AxiosConfig";
import EditComplaint from "@/components/EditComplaint.vue";
import router from "@/router";
const storeAuth = useAuthStore();
const { authUser, isAuthenticated, access_token } = storeToRefs(storeAuth);
const categorii = ref([]);
const isEditComplaintOpened = ref(false);
const isLoading = ref(true);
const complaint = ref();
// const complaint = ref({
//   descriere: "",
//   categorie: "",
//   raspuns: null,
//   status: "",
//   images: [],
//   user: "",
//   termeni_conditii: "",
// });
const updateComplaint = (newState) => {
  complaint.value = newState;
};
const route = useRoute();

const complaintId = ref(route.params.id);

function getStatusText(status) {
  switch (status) {
    case 0:
    case "0":
      return "Preluat";
    case 1:
    case "1":
      return "În lucru";
    case 2:
    case "2":
      return "Finalizat";
    case 3:
    case "3":
      return "Anulat";
    default:
      return "Status necunoscut";
  }
}
onMounted(() => {
  GetData(async () => {
    const response = await axios.get("/categorii");
    categorii.value = response.data.data;
  });

  GetData(async () => {
    const response = await axios.get(`/sesizari/${complaintId.value}`, {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });
    complaint.value = response.data.data;
    isLoading.value = false;
  }).catch((error) => {
    if (error.response && error.response.status === 404) {
      router.push({ name: "not-found" });
    }
  });
});
</script>
<style></style>
