<template>
  <div
    v-if="isOpened"
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 h-auto"
  >
    <div v-if="user" class="bg-white p-4 rounded-md w-2/4 max-lg:w-full mx-7">
      <h2 class="text-xl font-bold mb-4">Editare Utilizator</h2>

      <!-- Formularul pentru editarea unui utilizator -->
      <form @submit.prevent="editUser(user.id)">
        <div class="mb-4">
          <label for="nume" class="block text-sm font-medium text-gray-700"
            >Nume:</label
          >
          <input
            v-model="userToEdit.name"
            type="text"
            id="nume"
            name="nume"
            class="mt-1 p-2 border rounded-md w-full"
          />
          <span
            v-if="v$.name.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.name.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email:</label
          >
          <input
            v-model="userToEdit.email"
            type="email"
            id="email"
            name="email"
            class="mt-1 p-2 border rounded-md w-full"
            autocomplete="on"
          />
          <span
            v-if="v$.email.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.email.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label for="rol" class="block text-sm font-medium text-gray-700"
            >Rol:</label
          >

          <select
            v-model="userToEdit.roles"
            id="rol"
            name="rol"
            class="mt-1 p-2 border rounded-md w-full"
            multiple
          >
            <option value="admin">Administrator</option>
            <option value="autoritate">Autoritate</option>
            <option value="user">Utilizator</option>
          </select>
          <span
            v-if="v$.roles.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.roles.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label
            for="parola"
            class="block text-sm font-medium text-gray-700 text-balance"
            >Schimbare Parolă:</label
          >
          <input
            v-model="userToEdit.new_password"
            type="password"
            id="parola"
            name="parola"
            class="mt-1 p-2 border rounded-md w-full"
            autocomplete="off"
          />
        </div>

        <div v-if="userToEdit.roles[0] !== 'user'" class="mb-1">
          <p class="block text-sm font-medium text-gray-700 underline">
            Intrebuințarea categoriilor pentru utilizator
          </p>
          <div class="flex flex-wrap">
            <div
              v-for="categorie in categorii"
              :key="categorie.id"
              class="mr-4 mb-2"
            >
              <input
                v-model="userToEdit.categorie_ids"
                :value="categorie.id"
                type="checkbox"
                :name="categorie.nume"
                class="m-2"
                :id="categorie.id"
              />
              <label :for="categorie.id">{{ categorie.nume }} </label>
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <button
            type="button"
            @click="setEditPopupClose"
            class="px-4 py-2 m-4 bg-gray-500 text-white rounded-md"
          >
            Renunță
          </button>
          <button
            type="submit"
            class="px-4 py-2 m-4 bg-blue-500 text-white rounded-md"
          >
            Modifică
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, toRefs, computed } from "vue";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, minLength } from "@vuelidate/validators";
const storeAuth = useAuthStore();
const { authUser, access_token } = storeToRefs(storeAuth);

const categorii = ref();

const props = defineProps(["user", "isOpened"]);
const { user, isOpened } = toRefs(props);

const userToEdit = ref({
  name: user.value.name,
  email: user.value.email,
  roles: user.value.roles,
  new_password: "",
  categorie_ids: user.value.category_ids,
});

const rulesEditUser = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Introdu un nume aici", required),
      minLength: helpers.withMessage(
        "Numele trebuie să fie de cel puțin 4 caractere",
        minLength(4)
      ),
    },
    email: {
      required: helpers.withMessage("Introdu o adresă de email", required),
      email: helpers.withMessage("Introduceți o adresă de email validă", email),
    },
    roles: {
      required: helpers.withMessage(
        "Alege unul sau mai multe roluri",
        required
      ),
    },
  };
});

const v$ = useVuelidate(rulesEditUser, userToEdit.value);
const emits = defineEmits(["is-edit-user-opened", "edited-user"]);

const setEditPopupClose = () => {
  emits("is-edit-user-opened", false);
  v$.value.$reset();
};
function hasUserRole() {
  return (
    userToEdit.value.roles.length === 1 && userToEdit.value.roles[0] === "user"
  );
}
const editUser = async (id) => {
  const validateEditUser = await v$.value.$validate();

  if (validateEditUser) {
    const editedUser = ref({
      name: userToEdit.value.name,
      email: userToEdit.value.email,
      roles: [...userToEdit.value.roles],
      password: userToEdit.value.new_password,
      categorie_ids: [...userToEdit.value.categorie_ids],
    });

    GetData(async () => {
      const response = await axios.patch(`/edit-user/${id}`, editedUser.value, {
        headers: {
          Authorization: `Bearer ${access_token.value}`,
        },
      });

      emits("edited-user", response.data.data);
      setEditPopupClose();
    }).catch((error) => {
      console.log(error);
    });
  }
};
onMounted(() => {
  GetData(async () => {
    const response = await axios.get("/categorii");
    categorii.value = response.data.data;
  });
});
</script>
