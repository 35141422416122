import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Register from "../views/RegisterForm.vue";
import Login from "../views/LoginForm.vue";
import ResetPassForm from "../views/ResetPassForm.vue";
import AboutComplaint from "../views/AboutComplaint.vue";
import ProfileUser from "../views/ProfileUser.vue";
import UsersPage from "../views/UsersPage.vue";
import Dashboard from "../views/Dashboard.vue";
import SesizariPerCategorie from "../views/SesizariPerCategorie.vue";
import MapSesizare from "../views/MapSesizare.vue";
import UserComplaints from "../views/UserComplaints.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ForbiddenPage from "../views/ForbiddenPage.vue";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";

// import guest from "../middleware/guest.js";
// import auth from "../middleware/auth.js";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  // {
  //   path: "/forgot-password",
  //   name: "password-recover",
  //   component: ResetPassForm,
  // },
  {
    path: "/about/:id",
    name: "about-complaint",
    component: AboutComplaint,
    meta: {
      requiresAuth: true,
      requiredRoles: ["admin", "autoritate", "user"],
    },
    // beforeEnter: (to) => {
    //   getSesizari();
    //   const id = parseInt(to.params.id);
    //   const exists = complaintsData.value.some(
    //     (sesizare) => sesizare.id === id
    //   );
    //   if (!exists) return { name: "not-found" };
    // },
  },
  {
    path: "/profile",
    name: "profile-user",
    component: ProfileUser,
    meta: {
      requiresAuth: true,
      requiredRoles: ["admin", "autoritate", "user"],
    },
  },
  {
    path: "/user-sesizari",
    name: "user-sesizare",
    component: UserComplaints,
    meta: {
      requiresAuth: true,
      requiredRoles: ["user"],
    },
  },
  {
    path: "/users",
    name: "users-page",
    component: UsersPage,
    meta: {
      requiresAuth: true,
      requiredRoles: ["admin"],
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiredRoles: ["admin", "autoritate"],
    },
  },
  {
    path: "/categorii/:id",
    name: "sesizari-categorie",
    component: SesizariPerCategorie,
    meta: {
      requiresAuth: true,
      requiredRoles: ["admin", "autoritate"], // aici ca autoritate imi da interzis
    },
  },
  {
    path: "/sesizare/:id",
    name: "sesizari-map",
    component: MapSesizare,
    meta: {
      requiresAuth: true,
      requiredRoles: ["admin", "autoritate", "user"],
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: ForbiddenPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
//redirect to intended route
router.beforeEach((to, from, next) => {
  const storeAuth = useAuthStore();
  const { authUser } = storeToRefs(storeAuth);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!storeAuth.isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      // Verifică dacă ruta necesită anumite roluri
      if (to.meta.requiredRoles) {
        const requiredRoles = to.meta.requiredRoles;
        const userRoles = authUser.value.roles;

        // Verifică dacă utilizatorul are unul dintre rolurile necesare
        const hasRequiredRole = requiredRoles.some((role) =>
          userRoles.includes(role)
        );

        if (!hasRequiredRole) {
          // Utilizatorul nu are un rol suficient pentru a accesa ruta
          // Poți redirecționa către o pagină de acces interzis sau orice alt comportament dorit
          next("/");
          // console.log("ACCESS DENIED!");
          // next("/access-denied");
          return; // Opțional: oprește continuarea rutei
        }
      }

      // Utilizatorul este autentificat și are rolul necesar, permite accesul
      next();
    }
  } else {
    // Ruta nu necesită autentificare, permite accesul
    next();
  }
});

export default router;
