<template>
  <div
    v-if="isOpened"
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75"
  >
    <div class="bg-white p-8 rounded-md w-1/4 max-xl:w-auto">
      <h2 class="text-xl font-bold mb-4">Adăugare Utilizator</h2>

      <!-- Formularul pentru adăugarea unui utilizator -->
      <form @submit.prevent="createNewUser">
        <div class="mb-4">
          <label for="nume" class="block text-sm font-medium text-gray-700"
            >Nume:</label
          >
          <input
            v-model="createUser.nume"
            type="text"
            id="nume"
            name="nume"
            class="mt-1 p-2 border rounded-md w-full"
          />
          <span
            v-if="v$.nume.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.nume.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email:</label
          >
          <input
            v-model="createUser.email"
            type="email"
            id="email"
            name="email"
            class="mt-1 p-2 border rounded-md w-full"
          />
          <span
            v-if="v$.email.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.email.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label for="rol" class="block text-sm font-medium text-gray-700"
            >Rol:</label
          >
          <select
            multiple
            v-model="createUser.roles"
            id="rol"
            name="rol"
            class="mt-1 p-2 border rounded-md w-full"
          >
            <!-- Opțiuni pentru selecția rolului -->
            <option value="admin">Administrator</option>
            <option value="autoritate">Autoritate</option>
            <option value="user">Utilizator</option>
          </select>
          <span
            v-if="v$.roles.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.roles.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label for="parola" class="block text-sm font-medium text-gray-700"
            >Parolă:</label
          >
          <input
            v-model="createUser.password"
            type="password"
            id="parola"
            name="parola"
            class="mt-1 p-2 border rounded-md w-full"
            autocomplete="off"
          />
          <span
            v-if="v$.password.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.password.$errors[0].$message }}</span
          >
        </div>

        <div class="mb-4">
          <label
            for="confirmareParola"
            class="block text-sm font-medium text-gray-700"
            >Confirmare Parolă:</label
          >
          <input
            v-model="createUser.password_confirm"
            type="password"
            id="confirmareParola"
            name="confirmareParola"
            class="mt-1 p-2 border rounded-md w-full"
            autocomplete="off"
          />
          <span
            v-if="v$.password_confirm.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.password_confirm.$errors[0].$message }}</span
          >
        </div>
        <span
          v-if="showErrorMessage && !v$.$error"
          class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
          >{{ showErrorMessage }}</span
        >
        <div class="flex justify-between">
          <button
            type="button"
            @click="setPopupClose"
            class="px-4 py-2 m-4 bg-gray-500 text-white rounded-md"
          >
            Înapoi
          </button>
          <button
            type="submit"
            class="px-4 py-2 m-4 bg-blue-500 text-white rounded-md"
          >
            Crează Utilizator
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { RouterLink, useRouter } from "vue-router";
import { ref, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import axios from "../AxiosConfig";
import { storeToRefs } from "pinia";
import GetData from "@/composables/GetData";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  minLength,
  sameAs,
} from "@vuelidate/validators";
const storeAuth = useAuthStore();
const { allUsers, access_token } = storeToRefs(storeAuth);
const { users, isOpened } = defineProps(["users", "isOpened"]);
const createUser = ref({
  nume: "",
  email: "",
  password: "",
  password_confirm: "",
  roles: [],
});
const showErrorMessage = ref("");
const rulescreateUser = computed(() => {
  return {
    nume: {
      required: helpers.withMessage("Introdu un nume aici", required),
      minLength: helpers.withMessage(
        "Numele trebuie să fie de cel puțin 4 caractere",
        minLength(4)
      ),
    },
    email: {
      required: helpers.withMessage("Introdu o adresă de email", required),
      email: helpers.withMessage("Introduceți o adresă de email validă", email),
    },
    password: {
      required: helpers.withMessage("Introdu o parolă", required),
      minLength: helpers.withMessage(
        "Parola trebuie să aibă cel puțin 8 caractere",
        minLength(8)
      ),
    },
    password_confirm: {
      required: helpers.withMessage("Introdu încă odată parola", required),
      sameAs: helpers.withMessage(
        "Acest câmp trebuie să fie la fel cu cel de mai sus",
        sameAs(createUser.value.password)
      ),
    },
    roles: {
      required: helpers.withMessage(
        "Alege unul sau mai multe roluri",
        required
      ),
    },
  };
});

const v$ = useVuelidate(rulescreateUser, createUser.value);
// inchid createUser popup
const emits = defineEmits(["is-create-user-open", "new-user"]);
const setPopupClose = () => {
  (createUser.value.nume = ""),
    (createUser.value.email = ""),
    (createUser.value.password = ""),
    (createUser.value.password_confirm = ""),
    (createUser.value.roles = []),
    emits("is-create-user-open", false);
  v$.value.$reset();
};
const createNewUser = async () => {
  const validateNewUser = await v$.value.$validate();
  if (validateNewUser) {
    const newUser = {
      name: createUser.value.nume,
      email: createUser.value.email,
      password: createUser.value.password,
      password_confirmation: createUser.value.password_confirm,
      roles: [...createUser.value.roles],
    };

    GetData(async () => {
      const response = await axios.post("/users-create", newUser, {
        headers: {
          Authorization: `Bearer ${access_token.value}`,
        },
      });
      // allUsers.value.push(response.data.data);
      emits("new-user", response.data.data);
      setPopupClose();
    }).catch((error) => {
      showErrorMessage.value = error.response.data.message;
    });
  }
};
</script>
