<template>
  <div class="w-full overflow-hidden">
    <l-map
      v-if="sesizare"
      style="z-index: 0; height: 100vh"
      ref="map"
      v-model:zoom="zoom"
      :center="[sesizare.coordonate.lat, sesizare.coordonate.lng]"
      :minZoom="minZoom"
      :options="{ zoomControl: false }"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-geo-json :geojson="geojsonTesluiOltCoords"></l-geo-json>
      <l-marker :lat-lng="[sesizare.coordonate.lat, sesizare.coordonate.lng]">
        <l-popup :lat-lng="[sesizare.coordonate.lat, sesizare.coordonate.lng]">
          <PopupContent
            :complaint="sesizare"
            @view-details="redirectToDetails"
          />
        </l-popup>
      </l-marker>
      <l-control-zoom position="bottomleft"></l-control-zoom>
      <!-- <l-control position="topleft">
        <button
          @click="router.go(-1)"
          class="font-black px-5 py-3 my-1 bg-black text-white rounded-md hover:bg-gray-800 transition duration-100 ease-in-out"
        >
          Înapoi
        </button>
      </l-control> -->
    </l-map>
    <Navbar v-if="isAuthenticated" />
  </div>
</template>
<script setup>
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LGeoJson,
  LMarker,
  LPopup,
  LControl,
  LControlZoom,
} from "@vue-leaflet/vue-leaflet";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import router from "@/router";
import GetData from "@/composables/GetData";
import axios from "../AxiosConfig";
import PopupContent from "@/components/PopupContent.vue";
import Navbar from "@/components/Navbar.vue";
import { geojsonTesluiOltCoords } from "../composables/JudetePolygons.js";
const zoom = ref(18);
const minZoom = ref(6); //scrollOut

const storeAuth = useAuthStore();
const { isAuthenticated, access_token } = storeToRefs(storeAuth);
const route = useRoute();

const redirectToDetails = (complaint) => {
  router.push({ name: "about-complaint", params: { id: complaint.id } });
};

const sesizareId = ref(route.params.id);
// console.log(sesizareId);
const sesizare = ref();
onMounted(() => {
  GetData(async () => {
    GetData(async () => {
      const response = await axios.get(`/sesizari/${sesizareId.value}`, {
        headers: {
          Authorization: `Bearer ${access_token.value}`,
        },
      });
      sesizare.value = response.data.data;
    }).catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 404) {
        router.push({ name: "not-found" });
      }
    });
  });
});
</script>
