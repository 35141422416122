<template>
  <Navbar />
  <div v-if="allUsers" class="background-container pt-28">
    <div class="container mx-auto overflow-x-auto">
      <!-- Buton pentru adăugarea unui utilizator -->
      <div class="flex justify-between items-center mb-4">
        <h1 class="text-4xl font-bold text-center text-gray-800">Users List</h1>
        <button
          @click="isCreateUserOpen = true"
          class="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Adaugă Utilizator
        </button>
      </div>
      <!-- Tabel pentru utilizatori -->
      <table class="w-full border-collapse border border-gray-400 bg-gray-100">
        <thead>
          <tr class="bg-gray-200">
            <th class="border p-2">Nume</th>
            <th class="border p-2 hidden sm:table-cell">Email</th>
            <th class="border p-2">Rol</th>
            <th class="border p-2">Editare</th>
            <th class="border p-2">Ștergere</th>
          </tr>
        </thead>
        <tbody class="text-center font-semibold">
          <!-- Iterează prin utilizatori și afișează rândurile tabelului -->
          <tr v-for="user in allUsers" :key="user.id">
            <td class="border p-2 text-balance text-center">{{ user.name }}</td>
            <td
              class="border p-2 text-balance text-center hidden sm:table-cell"
            >
              {{ user.email }}
            </td>
            <td class="border p-2 text-balance text-center">
              <span v-for="(rol, index) in user.roles" :key="index">
                {{ rol }}<br />
              </span>
              <!-- <span>
              {{ user.role[0] }}
            </span> -->
            </td>
            <td class="border p-2 text-center">
              <button
                @click="editUser(user)"
                class="text-blue-500 hover:text-blue-700 hover:underline"
              >
                Editare
              </button>
            </td>
            <td class="border p-2 text-center">
              <button
                @click="toDeleteUser(user)"
                class="text-red-500 hover:text-red-700 hover:underline"
              >
                Ștergere
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CreateUser
      :isOpened="isCreateUserOpen"
      @is-create-user-open="updateIsCreateUserOpen"
      @new-user="addUser"
    />
    <EditUser
      v-if="userToEdit"
      :isOpened="isEditUserOpened"
      @is-edit-user-opened="
        (newValue) => {
          isEditUserOpened = newValue;
          userToEdit = null;
        }
      "
      :user="userToEdit"
      @edited-user="updateUser"
    />
    <PopupAvertizareDelete
      v-if="userToDelete"
      :item-to-delete="userToDelete"
      item-type="user"
      :is-popup-opened="isDeletePopupOpened"
      @set-popup-close="
        (newValue) => {
          isDeletePopupOpened = newValue;
        }
      "
      @delete-item="deleteUser"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import CreateUser from "@/components/CreateUser.vue";
import EditUser from "@/components/EditUser.vue";
import PopupAvertizareDelete from "@/components/PopupAvertizareDelete.vue";

const storeAuth = useAuthStore();
const { isAuthenticated, access_token } = storeToRefs(storeAuth);
const isCreateUserOpen = ref(false);
const isEditUserOpened = ref(false);
const userToEdit = ref();
const allUsers = ref();
const userToDelete = ref();
const isDeletePopupOpened = ref(false);
const updateIsCreateUserOpen = (newValue) => {
  isCreateUserOpen.value = newValue;
};
const addUser = (newUser) => {
  allUsers.value.push(newUser);
};

const updateUser = (udatedUser) => {
  const index = allUsers.value.findIndex((user) => user.id === udatedUser.id);
  if (index !== -1) {
    // Actualizează utilizatorul cu noile date
    allUsers.value[index] = { ...udatedUser };
  }
};

const editUser = (user) => {
  userToEdit.value = user;
  isEditUserOpened.value = true;
};
const toDeleteUser = (user) => {
  userToDelete.value = user;
  isDeletePopupOpened.value = true;
};
function deleteUser(userId) {
  GetData(async () => {
    await axios.delete(`/delete-user/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });
    // Sterge utilizatorul din vector allUsers vizual
    const index = allUsers.value.findIndex((user) => user.id === userId);
    if (index !== -1) {
      allUsers.value.splice(index, 1);
    }
  });
}

onMounted(() => {
  if (isAuthenticated.value) {
    GetData(async () => {
      const response = await axios.get("/users", {
        headers: {
          Authorization: `Bearer ${access_token.value}`,
        },
      });
      allUsers.value = response.data.data;
    });
  }
});
</script>
