import { defineStore } from "pinia";
import { ref } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const userAnonim = ref(false);
  const isAuthenticated = ref(false);
  const access_token = ref();
  const refresh_token = ref();
  const allUsers = ref([]);
  // const authUser = ref({
  //   categorie: [],
  //   email: "",
  //   name: "",
  //   permission: [],
  //   roles: [],
  // });
  const authUser = ref(null);

  //cand dai refresh desi esti logat(autologin)
  const storageAuthValue = JSON.parse(
    window.localStorage.getItem("isAuthenticated")
  );
  if (storageAuthValue) {
    isAuthenticated.value = storageAuthValue;
  }
  const storageAuthUser = window.localStorage.getItem("authUser");
  if (storageAuthUser) {
    authUser.value = JSON.parse(storageAuthUser);
  }
  //load Tokens From LocalStorage
  const storageAccessToken = window.localStorage.getItem("access_token");
  if (storageAccessToken) {
    access_token.value = storageAccessToken;
  }
  const storageRefreshToken = window.localStorage.getItem("refresh_token");
  if (storageRefreshToken) {
    refresh_token.value = storageRefreshToken;
  }

  function setData(response) {
    authUser.value = response.user;
    access_token.value = response.access_token;
    refresh_token.value = response.refresh_token;
    window.localStorage.setItem("authUser", JSON.stringify(authUser.value));
    window.localStorage.setItem("access_token", access_token.value);
    window.localStorage.setItem("refresh_token", refresh_token.value);
  }

  return {
    authUser,
    allUsers,
    access_token,
    refresh_token,
    userAnonim,
    isAuthenticated,
    setData,
  };
});
