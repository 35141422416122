<template>
  <div
    v-if="isEditComplaintOpened"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div v-if="complaintToEdit" class="bg-white p-6 rounded-md w-full max-w-xl">
      <h2 class="text-xl font-bold mb-4">Editează Sesizarea</h2>
      <form @submit.prevent="editComplaint(complaintToEdit.id)">
        <div class="flex justify-start text-sm font-medium text-gray-700">
          <span class="mr-1">Data:</span>
          <span>{{ complaintToEdit.created_at }}</span>
        </div>
        <!-- Userul care a creat sesizare-->
        <div v-if="complaintToEdit.user">
          <div class="grid grid-cols-2 gap-4">
            <div>
              <span class="text-sm font-medium text-gray-700">Creat de: </span>
              <span>{{ complaintToEdit.user.name }}</span>
            </div>
            <div>
              <span class="text-sm font-medium text-gray-700">Email: </span>
              <span>{{ complaintToEdit.user.email }}</span>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="descriere" class="block text-sm font-medium text-gray-700"
            >Descrierea sesizării</label
          >
          <textarea
            v-model="complaintToEdit.descriere"
            id="descriere"
            name="descriere"
            rows="3"
            class="mt-1 p-2 border rounded w-full"
            readonly
          ></textarea>
        </div>

        <div class="mb-3">
          <label for="categoria" class="font-bold">Editeaza categoria</label>
          <select
            v-model="editSesizare.categorie"
            id="categoria"
            name="categoria"
            class="mt-1 p-2 border rounded w-full"
          >
            <option
              v-for="categorie in categorii"
              :key="categorie.id"
              :value="categorie.id"
            >
              {{ categorie.nume }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="stare" class="font-bold">Editeaza starea</label>
          <select
            v-model="editSesizare.status"
            id="stare"
            name="stare"
            class="mt-1 p-2 border rounded w-full"
          >
            <option value="0">Preluat</option>
            <option value="1">In lucru</option>
            <option value="2">Finalizat</option>
            <option value="3">Anulat</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="raspuns" class="font-bold">Adauga raspuns</label>
          <textarea
            v-model="editSesizare.raspuns"
            id="raspuns"
            name="raspuns"
            rows="3"
            class="mt-1 p-2 border rounded w-full"
          ></textarea>
          <span
            v-if="v$.raspuns.$error"
            class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >{{ v$.raspuns.$errors[0].$message }}</span
          >
        </div>
        <div class="flex justify-center">
          <button
            type="button"
            @click="setEditPopupClose"
            class="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md mr-20"
          >
            Renunță
          </button>
          <button
            type="submit"
            class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
          >
            Accepta modificarile
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, toRefs } from "vue";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const storeAuth = useAuthStore();
const { access_token } = storeToRefs(storeAuth);
const categorii = ref([]);

const props = defineProps(["complaint-to-edit", "is-edit-complaint-opened"]);
const { complaintToEdit, isEditComplaintOpened } = toRefs(props);
const emits = defineEmits(["is-edit-complaint-opened", "edited-completed"]);
const editSesizare = ref({
  categorie: complaintToEdit.value.categorie,
  status: complaintToEdit.value.status,
  raspuns: complaintToEdit.value.raspuns,
});
const ruleEditSesizare = computed(() => {
  return {
    raspuns: {
      required: helpers.withMessage("Introdu un raspuns aici", required),
    },
  };
});
const v$ = useVuelidate(ruleEditSesizare, editSesizare.value);
const setEditPopupClose = () => {
  emits("is-edit-complaint-opened", false);
  v$.value.$reset();
};

const editComplaint = async (id) => {
  const validateEdit = await v$.value.$validate();

  if (validateEdit) {
    GetData(async () => {
      const response = await axios.patch(
        `/sesizari/${id}`,
        editSesizare.value,
        {
          headers: {
            Authorization: `Bearer ${access_token.value}`,
          },
        }
      );
      emits("edited-completed", response.data.data);
      setEditPopupClose();
    }).catch((error) => {
      console.log(error);
    });
  }
};
onMounted(() => {
  GetData(async () => {
    const response = await axios.get("/categorii");
    categorii.value = response.data.data;
  });
});
</script>
