<template>
  <div class="background-container flex flex-col items-center pt-5">
    <Navbar />
    <div
      v-if="isLoading"
      class="fixed flex justify-center items-center top-1/3 font-bold"
    >
      <p class="text-lg">Se încarcă categoriile...</p>
    </div>
    <div
      v-else-if="categorii.length === 0"
      class="fixed flex justify-center items-center top-1/3 font-bold"
    >
      <p class="text-lg">Nu aveți încă categorii asociate contului dvs.</p>
    </div>
    <div v-else class="text-left mt-14 p-10 rounded-md w-auto bg-gray-100">
      <h2 class="text-xl font-bold mb-4">Sesizari per categorie</h2>
      <table class="mx-auto">
        <tbody>
          <tr v-for="categorie in categorii" :key="categorie.id" class="mb-4">
            <td class="mr-2 font-semibold border-b text-balance">
              {{ categorie.nume }}
            </td>

            <td>
              <button
                @click="redirectToSesizariPerCategorie(categorie.id)"
                class="px-2 py-1 bg-blue-500 text-white rounded-md mx-2"
              >
                Lista Sesizari
              </button>
            </td>
            <td v-if="authUser.roles[0] === 'admin'">
              <button
                @click="toDeleteCategory(categorie)"
                class="px-2 py-1 bg-red-500 text-white rounded-md"
              >
                Stergere
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        v-if="hasAdminRole(authUser)"
        @click="isAddNewCategoryOpen = true"
        class="px-4 py-2 bg-green-500 text-white rounded-md mt-4"
      >
        Adaugă Categorie Nouă
      </button>
    </div>
  </div>
  <AddNewCategory
    :isAddNewCategoryOpen="isAddNewCategoryOpen"
    @set-add-new-category="(newValue) => (isAddNewCategoryOpen = newValue)"
    @add-category="addCategory"
  />
  <PopupAvertizareDelete
    v-if="categorie"
    :item-to-delete="categorie"
    item-type="categorie"
    :is-popup-opened="isDeletePopupCategoryOpened"
    @set-popup-close="
      (newValue) => {
        isDeletePopupCategoryOpened = newValue;
      }
    "
    @delete-item="stergeCategorie"
  />
</template>
<script setup>
import Navbar from "../components/Navbar.vue";
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";
import router from "@/router";
import AddNewCategory from "@/components/AddNewCategory.vue";
import GetData from "@/composables/GetData";
import axios from "../AxiosConfig";
import PopupAvertizareDelete from "@/components/PopupAvertizareDelete.vue";

const storeAuth = useAuthStore();
const { authUser, access_token } = storeToRefs(storeAuth);
const categorii = ref([]);
const isAddNewCategoryOpen = ref(false);
const categorie = ref();
const isDeletePopupCategoryOpened = ref(false);
const isLoading = ref(true);

const redirectToSesizariPerCategorie = (categorieId) => {
  router.push({ name: "sesizari-categorie", params: { id: categorieId } });
};
function hasAdminRole(user) {
  return user && user.roles.includes("admin");
}
const toDeleteCategory = (categorieToDelete) => {
  categorie.value = categorieToDelete;

  isDeletePopupCategoryOpened.value = true;
};
const addCategory = (newCategory) => {
  categorii.value.push(newCategory);
};
// const updateCategory = (categoryID) => {
//
// };
const stergeCategorie = (categorieID) => {
  GetData(async () => {
    const response = await axios.delete(`/categorii/${categorieID}`, {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });

    // Sterge categoria vizual
    const index = categorii.value.findIndex(
      (categorie) => categorie.id === categorieID
    );
    if (index !== -1) {
      categorii.value.splice(index, 1);
    }
  }).catch((error) => {
    console.log(error);
  });
};
onMounted(() => {
  GetData(async () => {
    const response = await axios.get("/categorii/admin", {
      headers: {
        Authorization: `Bearer ${access_token.value}`,
      },
    });
    categorii.value = response.data.data;
    isLoading.value = false;
  });
});
</script>
<style>
.background-container {
  background-image: url("../assets/bg_admin.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}
</style>
