<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100 p-2">
    <div class="max-w-md w-full space-y-8 p-6 bg-white shadow-lg rounded-lg">
      <h2 class="text-center text-2xl font-bold text-gray-900">
        Create an account
      </h2>
      <form
        @submit.prevent="handleSubmit"
        class="space-y-4"
        action="#"
        method="POST"
      >
        <div>
          <label for="name" class="block text-sm font-medium text-gray-900"
            >Name</label
          >
          <div class="mt-2">
            <input
              v-model="registerData.nume"
              id="name"
              name="name"
              type="text"
              @input="errorMessage = ''"
              autocomplete="name"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1 px-1"
            />
            <span
              v-if="v$.nume.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ v$.nume.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium text-gray-900"
            >Email address</label
          >
          <div class="mt-2">
            <input
              v-model="registerData.email"
              id="email"
              name="email"
              type="text"
              @input="errorMessage = ''"
              autocomplete="email"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1 px-1"
            />
            <span
              v-if="v$.email.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ v$.email.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-900"
            >Password</label
          >
          <div class="mt-2">
            <input
              v-model="registerData.password"
              id="password"
              name="password"
              type="password"
              @input="errorMessage = ''"
              autocomplete="new-password"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1 px-1"
            />

            <span
              v-if="v$.password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ v$.password.$errors[0].$message }}</span
            >
          </div>
        </div>

        <div>
          <label
            for="password_confirmation"
            class="block text-sm font-medium text-gray-900"
            >Confirm Password</label
          >
          <div class="mt-2">
            <input
              v-model="registerData.passwordConfirm"
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              autocomplete="new-password"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-1 px-1"
            />
            <span
              v-if="v$.passwordConfirm.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ v$.passwordConfirm.$errors[0].$message }}</span
            >
            <span
              v-if="errorMessage && !v$.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ errorMessage }}</span
            >
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create Account
          </button>
        </div>
      </form>
      <p class="text-center text-sm text-gray-500">
        Already have an account?
        <router-link
          to="/login"
          class="font-semibold text-indigo-600 hover:text-indigo-500"
          >Sign in</router-link
        >
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import axios from "../AxiosConfig";
// import { useRouter, router } from "vue-router";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  minLength,
  sameAs,
} from "@vuelidate/validators";

const storeAuth = useAuthStore();
const { isAuthenticated, userAnonim } = storeToRefs(storeAuth);
const registerData = ref({
  nume: "",
  email: "",
  password: "",
  passwordConfirm: "",
});
const errorMessage = ref("");
const rulesRegister = computed(() => {
  return {
    nume: {
      required: helpers.withMessage("Introdu un nume aici", required),
      minLength: helpers.withMessage(
        "Numele trebuie să fie de cel puțin 4 caractere",
        minLength(4)
      ),
    },
    email: {
      required: helpers.withMessage("Introdu o adresă de email", required),
      email: helpers.withMessage("Introduceți o adresă de email validă", email),
    },
    password: {
      required: helpers.withMessage("Introdu o parolă", required),
      minLength: helpers.withMessage(
        "Parola trebuie să aibă cel puțin 8 caractere",
        minLength(8)
      ),
    },
    passwordConfirm: {
      required: helpers.withMessage("Introdu încă odată parola", required),
      sameAs: helpers.withMessage(
        "Acest câmp trebuie să fie la fel cu cel de mai sus",
        sameAs(registerData.value.password)
      ),
    },
  };
});
const v$ = useVuelidate(rulesRegister, registerData.value);
const handleSubmit = async () => {
  const validateRegister = await v$.value.$validate();

  if (validateRegister) {
    const dataSend = ref({
      name: registerData.value.nume,
      email: registerData.value.email,
      password: registerData.value.password,
      password_confirmation: registerData.value.passwordConfirm,
    });
    // POST pentru a adăuga datele în db.json
    axios
      .post("/register", dataSend.value)
      .then((response) => {
        isAuthenticated.value = true;
        window.localStorage.setItem("isAuthenticated", true);
        userAnonim.value = false;
        window.localStorage.setItem("userAnonim", false);
        storeAuth.setData(response.data);
        // Resetarea stării validării
        registerData.value.name = "";
        registerData.value.email = "";
        registerData.value.password = "";
        registerData.value.passwordConfirm = "";
        v$.value.$reset();
        router.push("/");
      })
      .catch((error) => {
        errorMessage.value = error.response.data.message;
      });
  }
};
</script>
<style></style>
