<template>
  <Navbar />
  <div
    class="background-container flex justify-center items-start h-screen bg-gray-100"
  >
    <div
      class="flex justify-center w-full max-w-screen-md mt-28 p-4 border rounded-md shadow-md bg-white"
    >
      <!-- Formular pentru informațiile profilului -->
      <div class="w-1/2 m-2">
        <h2 class="text-2xl font-bold mb-4">Informații Profil</h2>
        <form @submit.prevent="updateProfile">
          <div class="mb-4">
            <label for="name" class="block text-sm font-medium text-gray-600"
              >Nume:</label
            >
            <!-- v-model="profile.name" -->
            <input
              v-model="editAuthUser.name"
              type="text"
              id="name"
              autocomplete="on"
              @input="successMessageProfile = ''"
              class="mt-1 p-2 w-full border rounded"
            />
            <span
              v-for="error in v$1.name.$errors"
              :key="error.$uid"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              {{ error.$message }}
            </span>
          </div>

          <div class="mb-4">
            <label for="email" class="block text-sm font-medium text-gray-600"
              >Email:</label
            >
            <!-- v-model="profile.email" -->
            <input
              v-model="editAuthUser.email"
              type="text"
              id="email"
              autocomplete="on"
              @input="successMessageProfile = ''"
              class="mt-1 p-2 w-full border rounded"
            />
            <span
              v-for="error in v$1.email.$errors"
              :key="error.$uid"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ error.$message }}</span
            >
          </div>
          <span
            v-if="successMessageProfile && !v$1.$error"
            class="text-blue-500 font-bold mb-1"
          >
            {{ successMessageProfile }}
          </span>
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none mb-2"
          >
            Actualizează Profil
          </button>
        </form>
        <!-- Formular pentru stergerea contului -->
        <h2 class="text-2xl font-bold text-gray-900">Șterge-ți contul</h2>
        <form @submit.prevent="deleteAccount">
          <p v-if="isAccountDeleted" class="my-2 font-medium">
            <span class="text-red-500 font-bold">Atenție!</span> Odată ce contul
            va fi șters, toate datele și setările contului vor disparea.
          </p>
          <button
            v-if="!isAccountDeleted"
            @click="isAccountDeleted = true"
            class="bg-blue-500 mt-4 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
          >
            Șterge Contul
          </button>
          <div v-if="isAccountDeleted" class="mb-3">
            <label
              for="currentPassword"
              class="block text-sm font-medium text-gray-600"
              >Parola contului:</label
            >
            <input
              v-model="accountDeletePassword.password"
              type="password"
              id="currentPassword"
              @input="showDeleteUserPassError = false"
              autocomplete="on"
              class="mt-1 p-2 w-full border rounded"
            />
            <span
              v-if="showDeleteUserPassError && !v$3.password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              Parola introdusă este incorectă.
            </span>
            <span
              v-if="v$3.password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              {{ v$3.password.$errors[0].$message }}
            </span>
          </div>
          <button
            v-if="isAccountDeleted"
            type="submit"
            class="bg-red-500 mt-2 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none mr-8"
          >
            Șterge Contul
          </button>
          <button
            v-if="isAccountDeleted"
            @click="renuntaDeleteUser"
            class="bg-gray-500 mt-2 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none"
          >
            Renunță
          </button>
        </form>
      </div>

      <!-- Formular pentru schimbarea parolei -->
      <div class="w-1/2 m-2">
        <h2 class="text-2xl font-bold mb-4">Schimbare Parolă</h2>
        <form @submit.prevent="changePassword">
          <div class="mb-3">
            <label
              for="currentPassword"
              class="block text-sm font-medium text-gray-600"
              >Parola Curentă:</label
            >
            <input
              v-model="editAuthUser.current_password"
              type="password"
              id="currentPassword"
              @input="
                (showEditPasswordError = false), (successMessagePassword = '')
              "
              autocomplete="on"
              class="mt-1 p-2 w-full border rounded"
            />
            <span
              v-if="showEditPasswordError && !v$2.current_password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              Parola introdusă este incorectă.
            </span>
            <!-- <span
              v-for="error in v$2.current_password.$errors"
              :key="error.$uid"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ error.$message }}</span
            > -->
            <span
              v-if="v$2.current_password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              {{ v$2.current_password.$errors[0].$message }}
            </span>
          </div>

          <div class="mb-4">
            <label
              for="newPassword"
              class="block text-sm font-medium text-gray-600"
              >Parolă Nouă:</label
            >
            <input
              v-model="editAuthUser.new_password"
              type="password"
              id="newPassword"
              @input="
                (showEditPasswordError = false), (successMessagePassword = '')
              "
              autocomplete="on"
              class="mt-1 p-2 w-full border rounded"
            />
            <!-- <span
              v-for="error in v$2.new_password.$errors"
              :key="error.$uid"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
              >{{ error.$message }}</span
            > -->
            <span
              v-if="v$2.new_password.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              {{ v$2.new_password.$errors[0].$message }}
            </span>
          </div>

          <div class="mb-4">
            <label
              for="confirmNewPassword"
              class="block text-sm font-medium text-gray-600"
              >Confirmă Parola Nouă:</label
            >
            <!-- v-model="passwords.confirmNewPassword" -->
            <input
              v-model="editAuthUser.password_confirmation"
              type="password"
              id="confirmNewPassword"
              @input="
                (showEditPasswordError = false), (successMessagePassword = '')
              "
              autocomplete="on"
              class="mt-1 p-2 w-full border rounded"
            />

            <span
              v-if="v$2.password_confirmation.$error"
              class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
            >
              {{ v$2.password_confirmation.$errors[0].$message }}
            </span>
            <span
              v-if="successMessagePassword && !v$2.$error"
              class="text-green-500 font-semibold my-1 text-sm py-1 rounded"
            >
              {{ successMessagePassword }}
            </span>
          </div>
          <button
            type="submit"
            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none"
          >
            Schimbă Parola
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import Navbar from "@/components/Navbar.vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import GetData from "@/composables/GetData";
import axios from "../AxiosConfig";
import { ref, computed } from "vue";
import { forceLogout } from "../composables/GetData";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  minLength,
  sameAs,
} from "@vuelidate/validators";

const storeAuth = useAuthStore();
const { authUser, access_token } = storeToRefs(storeAuth);
const showEditPasswordError = ref(false);
const successMessageProfile = ref("");
const successMessagePassword = ref("");

const isAccountDeleted = ref(false); //enable delete button
const showDeleteUserPassError = ref(false); //parola introdusa este gresita
const accountDeletePassword = ref({
  password: "",
});

const editAuthUser = ref({
  categorie: [],
  email: authUser.value.email,
  name: authUser.value.name,
  current_password: "",
  new_password: "",
  password_confirmation: "",
  permission: [],
  roles: [],
});

function renuntaDeleteUser() {
  isAccountDeleted.value = false;
  showDeleteUserPassError.value = false;
  accountDeletePassword.value.password = "";
  v$3.value.$reset();
}
const rulesProfile = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Acest câmp nu poate fi gol", required),
      minLength: helpers.withMessage(
        "Acest câmp trebuie să aibă cel puțin 4 caractere",
        minLength(4)
      ),
    },
    email: {
      required: helpers.withMessage("Acest câmp nu poate fi gol", required),
      email: helpers.withMessage("Introduceți o adresă de email validă", email),
    },
  };
});
const rulesPassword = computed(() => {
  return {
    current_password: {
      required: helpers.withMessage("Acest câmp nu poate fi gol", required),
      minLength: helpers.withMessage(
        "Acest câmp trebuie să aibă cel puțin 8 caractere",
        minLength(8)
      ),
    },
    new_password: {
      required: helpers.withMessage("Acest câmp nu poate fi gol", required),
      minLength: helpers.withMessage(
        "Acest câmp trebuie să aibă cel puțin 8 caractere",
        minLength(8)
      ),
    },
    password_confirmation: {
      required: helpers.withMessage("Acest câmp nu poate fi gol", required),
      sameAs: helpers.withMessage(
        "Acest câmp trebuie să fie la fel cu cel de mai sus",
        sameAs(editAuthUser.value.new_password)
      ),
    },
  };
});
const rulesDeleteAccount = computed(() => {
  return {
    password: {
      required: helpers.withMessage("Acest câmp nu poate fi gol", required),
      minLength: helpers.withMessage(
        "Acest câmp trebuie să aibă cel puțin 8 caractere",
        minLength(8)
      ),
    },
  };
});
const v$1 = useVuelidate(rulesProfile, editAuthUser.value);
const v$2 = useVuelidate(rulesPassword, editAuthUser.value);
const v$3 = useVuelidate(rulesDeleteAccount, accountDeletePassword.value);

async function updateProfile() {
  if (
    editAuthUser.value.name === authUser.value.name &&
    editAuthUser.value.email === authUser.value.email
  )
    successMessageProfile.value = "Nu s-au adus modificari userului!";
  else {
    const validateProfile = await v$1.value.$validate();

    if (validateProfile) {
      GetData(async () => {
        const response = await axios.patch(
          "/profile",
          {
            name: editAuthUser.value.name,
            email: editAuthUser.value.email,
            categorie_ids: [],
          },
          {
            headers: {
              Authorization: `Bearer ${access_token.value}`,
            },
          }
        );
        authUser.value = response.data.data;
        window.localStorage.setItem("authUser", JSON.stringify(authUser.value));
        successMessageProfile.value =
          "Informațiile au fost modificate cu succes!";
        //reset
        editAuthUser.value.email = authUser.value.email;
        editAuthUser.value.name = authUser.value.name;
        v$2.value.$reset();
      });
    }
  }
}

async function changePassword() {
  const validatePassword = await v$2.value.$validate();

  if (validatePassword) {
    GetData(async () => {
      const response = await axios.patch(
        "/oauth/password",
        {
          current_password: editAuthUser.value.current_password,
          password: editAuthUser.value.new_password,
          password_confirmation: editAuthUser.value.password_confirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token.value}`,
          },
        }
      );
      showEditPasswordError.value = false;
      successMessagePassword.value = "Parola a fost schimbată cu succes!";
      // Resetarea stării validării
      editAuthUser.value.current_password = "";
      editAuthUser.value.new_password = "";
      editAuthUser.value.password_confirmation = "";
      v$2.value.$reset();
    }).catch((error) => {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.message === "The password is incorrect."
      ) {
        showEditPasswordError.value = true;
      }
    });
  }
}

async function deleteAccount() {
  // Stergerea contului din authUser
  const validatePassword = await v$3.value.$validate();

  if (validatePassword) {
    GetData(async () => {
      const response = await axios.delete("/profile", {
        headers: {
          Authorization: `Bearer ${access_token.value}`,
        },
        data: { password: accountDeletePassword.value.password },
      });

      forceLogout();
      //reset
      accountDeletePassword.value.password = "";
      v$3.value.$reset();
      router.push("/");
    }).catch((error) => {
      showDeleteUserPassError.value = true;
    });
  }
}
</script>
