import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_URL,
  withCredentials: true,
  // timeout: 5000,
});

// instance.interceptors.request.use((config) => {
//   const storeAuth = useAuthStore();
//   const { access_token } = storeToRefs(storeAuth);
//   config.headers.Authorization = `Bearer ${access_token.value}`;
//   return config;
// });
// axios.interceptors.request.use(
//   (config) => {
//     config.headers["Authorization"] = `Bearer ${localStorage.getItem(
//       "access_token"
//     )}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default instance;
