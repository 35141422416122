<template>
  <Navbar />
  <div class="background-container pt-28">
    <div class="container mx-auto overflow-x-auto">
      <!-- Buton pentru adăugarea unui utilizator -->
      <div class="flex justify-between items-center mb-4">
        <h1 class="text-4xl font-bold text-center text-gray-800">
          Sesizarile Tale
        </h1>
        <button
          class="bg-blue-500 text-white px-4 py-2 rounded"
          @click="router.push('/')"
        >
          Adaugă Sesizare
        </button>
      </div>

      <div
        v-if="isLoading"
        class="flex justify-center mt-12 items-center font-bold"
      >
        <p class="text-lg">Se încarcă sesizariile...</p>
      </div>
      <div
        v-else-if="complaintsData.length === 0"
        class="flex justify-center mt-12 items-center font-bold"
      >
        <p class="text-lg">Nu aveți încă nicio sesizare creată.</p>
      </div>

      <table
        v-else
        class="w-full border-collapse border border-gray-400 bg-gray-100"
      >
        <thead>
          <tr class="bg-gray-200">
            <th class="border p-2">Categoria</th>
            <th class="border p-2 hidden sm:table-cell">Descriere</th>
            <th class="border p-2">Stare</th>
            <th class="border p-2 hidden sm:table-cell">Raspuns</th>
            <th class="border p-2">Locația</th>
            <th class="border p-2">Acțiuni</th>
          </tr>
        </thead>
        <tbody class="text-center font-semibold">
          <!-- Iterează prin sesizari și afișează rândurile tabelului -->
          <tr v-for="complaint in complaintsData" :key="complaint.id">
            <td class="border p-2 text-balance">
              {{ complaint.categorie_name }}
            </td>
            <td class="border p-2 hidden sm:table-cell text-balance">
              <span v-if="complaint.descriere.length > 50">
                {{ complaint.descriere.substring(0, 30) + "..." }}</span
              >
              <span v-else>{{ complaint.descriere }}</span>
            </td>
            <td class="border p-2 text-balance">
              {{ getStatusText(complaint.status) }}
            </td>
            <td class="border p-2 hidden sm:table-cell text-balance">
              <span v-if="complaint.raspuns !== null">{{
                complaint.raspuns
              }}</span>
              <span v-else>Niciun răspuns încă</span>
            </td>

            <td class="border p-2">
              <button
                @click="redirectToMapSesizare(complaint.id)"
                class="text-green-500 hover:text-green-700 hover:underline text-balance"
              >
                Vezi pe hartă
              </button>
            </td>
            <td class="border p-2">
              <button
                @click="redirectToDetails(complaint.id)"
                class="text-blue-500 hover:text-blue-700 hover:underline text-balance"
              >
                Vezi mai mult
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import axios from "../AxiosConfig";
import GetData from "@/composables/GetData";
import { useAuthStore } from "../stores/auth";
import { storeToRefs } from "pinia";
import router from "@/router";

const isLoading = ref(true);
const complaintsData = ref([]);
const storeAuth = useAuthStore();
const { authUser, access_token } = storeToRefs(storeAuth);

const redirectToMapSesizare = (sesizareId) => {
  router.push({ name: "sesizari-map", params: { id: sesizareId } });
};
const redirectToDetails = (complaintId) => {
  router.push({ name: "about-complaint", params: { id: complaintId } });
};

function getStatusText(status) {
  switch (status) {
    case 0:
    case "0":
      return "Preluat";
    case 1:
    case "1":
      return "În lucru";
    case 2:
    case "2":
      return "Finalizat";
    case 3:
    case "3":
      return "Anulat";
    default:
      return "Status necunoscut";
  }
}

onMounted(() => {
  if (authUser.value.roles[0] == "user") {
    GetData(async () => {
      const response = await axios.get("/user-sesizare", {
        headers: {
          Authorization: `Bearer ${access_token.value}`,
        },
      });
      complaintsData.value = response.data.data;
      isLoading.value = false;
    });
  }
});
</script>
