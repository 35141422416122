<template>
  <div
    v-if="isAddNewCategoryOpen"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white p-6 rounded-md w-96">
      <h2 class="text-xl font-bold mb-4">Adaugă Categorie Nouă</h2>
      <input
        v-model="newCategory.name"
        type="text"
        placeholder="Nume categorie"
        class="p-2 border rounded-md w-full"
      />
      <span
        v-if="v$.name.$error"
        class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
        >{{ v$.name.$errors[0].$message }}</span
      >
      <span
        v-if="showErrorMessage && !v$.$error"
        class="text-red-500 font-semibold my-1 text-sm py-1 rounded"
        >{{ showErrorMessage }}</span
      >
      <div class="flex justify-center m-4">
        <button
          @click="setPopupClose"
          class="px-4 py-2 bg-gray-500 text-white rounded-md mr-4"
        >
          Renunță
        </button>
        <button
          @click="adaugaCategorie"
          class="px-4 py-2 bg-green-500 text-white rounded-md"
        >
          Adaugă
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";

import { storeToRefs } from "pinia";
import GetData from "@/composables/GetData";
import axios from "../AxiosConfig";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, minLength } from "@vuelidate/validators";

const storeAuth = useAuthStore();
const { access_token } = storeToRefs(storeAuth);
const showErrorMessage = ref("");
const categorii = ref();
const { isAddNewCategoryOpen } = defineProps(["isAddNewCategoryOpen"]);
const newCategory = ref({
  name: "",
});

const rulesNewCategory = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Acest camp nu poate fi gol", required),
      minLength: helpers.withMessage(
        "Numele trebuie să fie de cel puțin 4 caractere",
        minLength(4)
      ),
    },
  };
});
const v$ = useVuelidate(rulesNewCategory, newCategory.value);
// inchid AddNewCategory
const emits = defineEmits(["set-add-new-category", "add-category"]);
const setPopupClose = () => {
  newCategory.value.name = "";
  emits("set-add-new-category", false);
  v$.value.$reset();
};

const adaugaCategorie = async () => {
  const validateNewCategory = await v$.value.$validate();
  if (validateNewCategory) {
    GetData(async () => {
      const response = await axios.post(
        "/categorii",
        { nume: newCategory.value.name },
        {
          headers: {
            Authorization: `Bearer ${access_token.value}`,
          },
        }
      );
      // categorii.value.push(response.data);
      emits("add-category", response.data);
      setPopupClose();
    }).catch((error) => {
      showErrorMessage.value = error.response.data.message;
    });
  }
};
onMounted(() => {
  GetData(async () => {
    const response = await axios.get("/categorii");
    categorii.value = response.data.data;
  });
});
</script>
