<template>
  <div class="flex flex-col w-auto">
    <div class="flex flex-wrap gap-1 items-center">
      <i class="mdi mdi-file-document text-blue-700 text-lg"></i>
      <span class="font-bold">Categorie:</span>
      <p class="!my-0 break-words text-balance">
        {{ complaint.categorie_name }}
      </p>
    </div>
    <div class="flex gap-1 items-center">
      <i class="mdi mdi-help-circle-outline text-blue-700 text-lg"></i>
      <span class="font-bold">Status sesizare:</span>
      <p>{{ getStatusText(complaint.status) }}</p>
    </div>
    <div v-if="complaint.descriere" class="flex flex-wrap gap-1 items-center">
      <i
        class="mdi mdi-information-variant-circle-outline text-blue-700 text-lg"
      ></i>
      <span class="font-bold">Descriere:</span>
      <p class="break-words !my-0 text-balance">
        <span v-if="complaint.descriere.length > 50">
          {{ complaint.descriere.substring(0, 50) + "..." }}</span
        >
        <span v-else>{{ complaint.descriere }}</span>
      </p>
    </div>
    <div class="flex gap-1 items-center">
      <i class="mdi mdi-calendar text-blue-700 text-lg"></i>
      <span class="font-bold !my-0">Creat la:</span>
      <p>{{ complaint.created_at }}</p>
    </div>
    <button
      v-if="authUser && hasRole(authUser) && userAnonim === false"
      @click="redirectToDetails"
      class="bg-green-500 text-white px-4 py-2 rounded m-1 mx-6"
    >
      Vezi mai mult
    </button>
  </div>
</template>
<script setup>
import { ref, toRefs } from "vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

const storeAuth = useAuthStore();
const { userAnonim, authUser } = storeToRefs(storeAuth);
const props = defineProps(["complaint"]);
const { complaint } = toRefs(props);

const categorii = ref([]);
const emit = defineEmits(["view-details"]);
const redirectToDetails = () => {
  emit("view-details", complaint);
};

function getStatusText(status) {
  switch (status) {
    case 0:
    case "0":
      return "Preluat";
    case 1:
    case "1":
      return "În lucru";
    case 2:
    case "2":
      return "Finalizat";
    case 3:
    case "3":
      return "Anulat";
    default:
      return "Status necunoscut";
  }
}

function hasRole(user) {
  return user.roles[0] === "autoritate" || user.roles[0] === "user";
}
</script>
<style></style>
